import arrow from "../../../images/account-arrow.png";
import activeArrow from "../../../images/account-arrow-active.png";

export default theme => ({
    accountNavWrapper: {
        position: 'relative',
        filter: 'drop-shadow(0 3px 6px rgba(0,0,0,0.16))',
        '& .account-nav': {
            backgroundColor: 'rgba(27,54,93,0.1)',
            position: 'relative',
            zIndex: 2,
            '& a': {
                backgroundColor: 'transparent',
                fontFamily: theme.headerFont,
                fontSize: 14,
                color: theme.palette.primary.main,
                border: 'none',
                padding: 12,
                borderBottom: '1px solid #727C8E1A',
                display: 'block',
                width: '100%',
                letterSpacing: '0.008em',
                textAlign: 'left',
                textDecoration: 'none',
                fontWeight: 'bold',
                '&[open]': {
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,
                },
                '&:nth-of-type(3n)': {
                    borderBottom: 'none',
                },
                [theme.breakpoints.up('sm')]: {
                    padding: 32,
                    position: 'relative',
                    '&::before': {
                        content: '""',
                        backgroundImage: 'url(' + arrow + ')',
                        display: 'block',
                        width: 79,
                        height: 70,
                        margin: '0 auto',
                        position: 'absolute',
                        right: 0,
                        top: 1,
                        transition: 'right .3s ease-in-out',
                    },
                    '&[aria-current="page"]::before': {
                        backgroundImage: 'url(' + activeArrow + ')',
                        right: -45,
                    }
                },
            }
        },
        '& .page-content': {
            marginBottom: 50,
            [theme.breakpoints.up('md')]: {
                marginBottom: 120,
            },
            '& > div': {
                display: 'none',
                '&[open]': {
                    display: 'block',
                }
            }
        }
    }
})