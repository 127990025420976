import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "gatsby";
import { makeStyles } from "@material-ui/styles";
import styles from "./styles";

import { isLoggedIn, getUser } from "../../../services/auth";
import { isBrowser } from "../../../helpers";

const useStyles = makeStyles((theme) => styles(theme));

const queryPriceList = (group) => {
  return {
    query: `
      {
        group(id: "${group}", idType: SLUG) {
            id
            groupFields {
              priceList {
                mediaItemUrl
              }
            }
        }
      }
    `,
  };
};

export default function AccountNavWrap(props) {
  const classes = useStyles();

  const [authenticated, setAuthentication] = useState(undefined);
  const [priceList, setPriceList] = useState(); //eslint-disable-line

  isBrowser && authenticated === undefined && handleAuthentication();

  async function handleAuthentication() {
    const response = await isLoggedIn();

    if (response.data.errors) {
      setAuthentication(false);
    } else {
      setAuthentication(response.data.data.user);
    }
  }

  useEffect(() => {
    if (!getUser().group) return null;

    axios
      .post(
        `${process.env.GATSBY_ADMIN_URL}graphql`,
        queryPriceList(getUser().group),
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((res) => res.data.data)
      .then((data) => {
        setPriceList(data.group.groupFields.priceList?.mediaItemUrl);
      });
  }, []);

  return (
    <div className={`account-header ${classes.accountNavWrapper}`}>
      <div className="account-nav">
        {getUser() && <Link to="/account/price-lists">Price Lists</Link>}
        <Link to="/account/offers">Latest Offers</Link>
      </div>
    </div>
  );
}
