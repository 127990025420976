import React from "react";
import { navigate } from "gatsby";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid/Grid";
import styles from "./styles";

import { logout } from "../../../services/auth";

const useStyles = makeStyles((theme) => styles(theme));

export default function AccountHeader(props) {
  const classes = useStyles();

  function handleLogout() {
    logout(() => navigate("/account/login"));
  }

  return (
    <div className={`account-header ${classes.accountHeader}`}>
      <Grid
        container
        spacing={0}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={6}>
          <div className="profile">
            <div className="info">
              <h1 className="name">{props.name}</h1>
              <p className="email">{props.email}</p>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="actions">
            <button className="link" onClick={handleLogout}>
              Sign Out
            </button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
